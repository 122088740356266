@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

html {
  background-color: #262326;
}

img {
  user-select: none;
}

a {
  background: -webkit-linear-gradient(35deg, #faeb61 20%, #fa814d 140%);
  background-clip: text;
  color: #e66bff;
  font-weight: 600;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.gold {
  background-color: -webkit-linear-gradient(35deg, #faeb61 20%, #fa814d 140%);
}

small {
  font-size: 0.75em;
}

code {
  font-family: monospace;
}

hr {
  border-color: rgba(250, 250, 250, 0.2);
  margin: 30px 0;
}

.App {
  background-color: #262326;
  color: #f2f4f7;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  font-size: 1.5em;
  letter-spacing: 0;
  line-height: 1.32;
  overflow-y: scroll;
  overflow-x: hidden;
}

.centered {
  text-align: center;
}

.container {
  font-size: 1em;
}

.content {
  padding: 6em;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-pad-right {
  padding-right: 30px;
}

.hidden {
  display: none;
}

.panel {
  background-color: rgba(38, 35, 38, 0.88);
  backdrop-filter: blur(4px);
  border-radius: 20px;
  box-shadow: 2px 6px 12px 2px rgba(0, 0, 0, 0.2);
  font-size: 0.85em;
  max-width: 50vw;
  padding: 60px 90px;
  text-align: left;
}

.header-container {
  padding: 0;
}

.hero-image {
  background-image: url("../assets/sample_cat_2.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15%;
  box-shadow: 10px 8px 19px 0 rgba(0, 0, 0, 1);
  width: 500px;
  height: 500px;
}

.hero-image.wiggle {
  animation: wiggle-animation 3s ease-in-out infinite;
}

.hero-image.wiggle:hover {
  animation-play-state: paused;
}

.banner-flicker {
  /* animation: paths 5s step-end infinite; */
  animation-name: paths, banner-flicker;
  animation-duration: 3000ms, 3000ms;
  animation-delay: 0ms, 3000ms;
  animation-iteration-count: infinite;
}
/* 20, 15, 10, 5 */
/* 5, 10, 15, 20 */
@keyframes banner-flicker {
  0% {
    background-image: url("../assets/sample_cat_2.png");
    animation-timing-function: steps(1, end);
  }
  20% {
    background-image: url("../assets/robo_paw_glitch.png");
    animation-timing-function: steps(1, end);
  }
  25% {
    background-image: url("../assets/sample_cat_2.png");
    animation-timing-function: steps(1, end);
  }
  40% {
    background-image: url("../assets/robo_paw_glitch.png");
    animation-timing-function: steps(1, end);
  }
  50% {
    background-image: url("../assets/sample_cat_2.png");
    animation-timing-function: steps(1, end);
  }
  60% {
    background-image: url("../assets/robo_paw_glitch.png");
    animation-timing-function: steps(1, end);
  }
  75% {
    background-image: url("../assets/sample_cat_2.png");
    animation-timing-function: steps(1, end);
  }
  80% {
    background-image: url("../assets/robo_paw_glitch.png");
    animation-timing-function: steps(1, end);
  }
  100% {
    background-image: url("../assets/sample_cat_2.png");
    animation-timing-function: steps(1, end);
  }
}

/* @keyframes banner-flicker {
  0% {
    background-image: url("../assets/sample_cat_2.png");
    animation-timing-function: steps(1, end);
  }
  25% {
    background-image: url("../assets/robo_paw_glitch.png");
    animation-timing-function: steps(1, end);
  }
  40% {
    background-image: url("../assets/sample_cat_2.png");
    animation-timing-function: steps(1, end);
  }
  50% {
    background-image: url("../assets/robo_paw_glitch.png");
    animation-timing-function: steps(1, end);
  }
  55% {
    background-image: url("../assets/sample_cat_2.png");
    animation-timing-function: steps(1, end);
  }
  65% {
    background-image: url("../assets/robo_paw_glitch.png");
    animation-timing-function: steps(1, end);
  }
  68% {
    background-image: url("../assets/sample_cat_2.png");
    animation-timing-function: steps(1, end);
  }
  100% {
    background-image: url("../assets/robo_paw_glitch.png");
    animation-timing-function: steps(1, end);
  }
} */

.gradient-text {
  background: -webkit-linear-gradient(45deg, #ff3e29 0%, #e66bff 100%);
  background-clip: text;
  background-size: 100% 200%;
  text-shadow: 2px 4px 12px rgba(220, 220, 220, 0.2);
  font-family: "Fredoka One";
  font-size: 4em;
  letter-spacing: 0;
  margin: 0;
  font-weight: 400;
  -webkit-background-clip: text;
  -webkit-background-size: 200% 200%;
  -webkit-text-fill-color: transparent;
  animation: gradient-animation 3s ease infinite;
  animation-play-state: paused;
}

.gradient-text.active {
  animation-play-state: running;
}

.not-yet {
  cursor: url("https://pawsperitycats.s3.us-west-2.amazonaws.com/eyeemoji.png")
      20 20,
    pointer !important;
  cursor: -webkit-image-set(
        url("https://pawsperitycats.s3.us-west-2.amazonaws.com/eyeemoji.png") 1x,
        url("https://pawsperitycats.s3.us-west-2.amazonaws.com/eyeemoji.png") 2x
      )
      15 15,
    pointer !important;
}

.cta-select {
  background-color: #383738;
  color: white;
  height: 70px;
  border: 0;
  width: 90px;
  margin: 0 20px 0 0;
  /*padding: 0 40px;*/
  border-radius: 20px;
  cursor: pointer;
  font-family: "Fredoka One";
  font-size: 1em;
  text-align: center;
}

.cta-button {
  color: white;
  height: 70px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Fredoka One";
  font-size: 1em;
  /*transform: translateY(0px);*/
  transition: 0.2s ease;
}

.cta-button:disabled {
  cursor: not-allowed;
}

.cta-button:hover:not(:disabled) {
  /*transform: translateY(-2px);*/
  /*box-shadow: 2px 5px 18px -10px rgba(0, 0, 0, 0.25);*/
  box-shadow: inset 4px 5px 18px -10px rgba(0, 0, 0, 0.75);
}

.connect-wallet-button {
  background: -webkit-linear-gradient(left, #faeb61, #fa814d);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.connect-wallet-button:hover {
  animation-play-state: paused;
}

.mint-error {
  display: block;
  margin-top: 10px;
  font-size: 0.75em;
}

.mint-button {
  background: -webkit-linear-gradient(left, #a200d6, #ff6fdf);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-right: 15px;
}

.discord-button {
  background: -webkit-linear-gradient(left, #5865f2, #2d37a6);
  background-size: 200% 200%;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: white;
  animation: gradient-animation 4s ease infinite;
}

.twitter-button {
  background: -webkit-linear-gradient(left, #1da1f2, #2d37a6);
  background-size: 200% 200%;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: white;
  animation: gradient-animation 4s ease infinite;
}

.opensea-button {
  background-color: rgb(32, 129, 226);
}

.footer-container {
  font-size: 0.75em;
  padding: 30px;
  text-align: center;
}

.twitter-logo {
  width: 35px;
  height: 35px;
}

.footer-text {
  font-size: 1em;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-webkit-keyframes wiggle-animation {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes wiggle-animation {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes wiggle-animation {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* nav */
.nav-container {
  background-color: #2b2a2b;
  position: fixed;
  left: 0;
  height: 100vh;
  width: 80px;
}

.nav-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.nav-icon {
  border-radius: 35%;
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease;
}

.nav-icon:hover {
  background-color: #383738;
  cursor: pointer;
}

.nav-icon img {
  width: 40px;
  height: 40px;
}

/* section containers */
.story-container {
  background-image: url("../assets/bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 90px 0;
}

.roadmap-container {
  background-color: #ff3e29;
  margin-top: 30px;
  background-image: url("../assets/tile.png");
  background-position: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 90px 0;
}

.gallery-container {
  margin-top: 30px;
  background-image: url("../assets/gallery.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
  width: 100vw;
}

.modal-close {
  background-image: url("../assets/x.png");
  background-position: center;
  background-size: cover;
  position: absolute;
  right: 30px;
  top: 30px;
  height: 20px;
  width: 20px;
  font-size: 2em;
  font-family: sans-serif;
  opacity: 0.75;
  transition: 0.2s ease;
  cursor: pointer;
}
.modal-close:hover {
  opacity: 1;
}

.modal-container {
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.modal-container > .panel {
  background-color: #383738;
  max-width: 600px;
  position: relative;
}

/* raffle */
.raffle-section {
  display: flex;
  flex-direction: column;
}

.raffle-preview {
  margin: 30px auto 0 auto;
  display: flex;
  align-items: center;
  width: 400px;
}

.raffle-text {
  margin-left: 60px;
}

.raffle-text em {
  font-size: 0.8em;
}

.raffle-text h3 {
  margin: -10px 0 0 0;
  font-size: 2em;
  font-family: "Fredoka One";
}

.airdrop {
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  height: 90px;
  width: 90px;
}

.question-mark {
  background-image: url("../assets/question.png");
}

.t-1 {
  background-image: url("../assets/phanta.jpeg");
}

.t-2 {
  background-image: url("../assets/drop2.png");
}

.t-3 {
  background-image: url("../assets/drop3.png");
}

.t-4 {
  background-image: url("../assets/karafuru.jpeg");
}

.t-5 {
  background-image: url("../assets/azuki.jpeg");
}

/* media queries */
@media screen and (max-width: 1342px) {
  .flex-container {
    flex-direction: column;
    flex-flow: column-reverse;
  }

  .flex-pad-right {
    padding-right: 0;
  }

  body {
    padding-top: 80px;
  }

  .nav-container {
    top: 0;
    height: 80px;
    width: 100vw;
    z-index: 1;
  }

  .nav-content {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .nav-icon {
    margin: 0 30px;
  }
}

@media screen and (max-width: 1050px) {
  .modal-container > .panel {
    padding-top: 40px;
  }

  .gradient-text {
    font-size: 2em;
  }

  .hero-image {
    width: 300px;
    height: 300px;
    margin-bottom: 30px;
  }

  .panel {
    padding: 30px;
    max-width: 80vw;
  }
  .content {
    padding: 30px;
  }
}

/* glitch */
.hero-container {
  position: relative;
  text-align: center;
}

.layers {
  position: relative;
}

.layers::before,
.layers::after {
  content: attr(data-text);
  position: absolute;
  width: 110%;
  z-index: -1;
}

.layers::before {
  top: 10px;
  left: 15px;
  color: #e0287d;
}

.layers::after {
  top: 5px;
  left: -10px;
  color: #1bc7fb;
}

.single-path {
  clip-path: polygon(
    0% 12%,
    53% 12%,
    53% 26%,
    25% 26%,
    25% 86%,
    31% 86%,
    31% 0%,
    53% 0%,
    53% 84%,
    92% 84%,
    92% 82%,
    70% 82%,
    70% 29%,
    78% 29%,
    78% 65%,
    69% 65%,
    69% 66%,
    77% 66%,
    77% 45%,
    85% 45%,
    85% 26%,
    97% 26%,
    97% 28%,
    84% 28%,
    84% 34%,
    54% 34%,
    54% 89%,
    30% 89%,
    30% 58%,
    83% 58%,
    83% 5%,
    68% 5%,
    68% 36%,
    62% 36%,
    62% 1%,
    12% 1%,
    12% 34%,
    60% 34%,
    60% 57%,
    98% 57%,
    98% 83%,
    1% 83%,
    1% 53%,
    91% 53%,
    91% 84%,
    8% 84%,
    8% 83%,
    4% 83%
  );
}

.paths {
  animation: paths 5s step-end infinite;
}

@keyframes paths {
  0% {
    clip-path: polygon(
      0% 43%,
      83% 43%,
      83% 22%,
      23% 22%,
      23% 24%,
      91% 24%,
      91% 26%,
      18% 26%,
      18% 83%,
      29% 83%,
      29% 17%,
      41% 17%,
      41% 39%,
      18% 39%,
      18% 82%,
      54% 82%,
      54% 88%,
      19% 88%,
      19% 4%,
      39% 4%,
      39% 14%,
      76% 14%,
      76% 52%,
      23% 52%,
      23% 35%,
      19% 35%,
      19% 8%,
      36% 8%,
      36% 31%,
      73% 31%,
      73% 16%,
      1% 16%,
      1% 56%,
      50% 56%,
      50% 8%
    );
  }

  5% {
    clip-path: polygon(
      0% 29%,
      44% 29%,
      44% 83%,
      94% 83%,
      94% 56%,
      11% 56%,
      11% 64%,
      94% 64%,
      94% 70%,
      88% 70%,
      88% 32%,
      18% 32%,
      18% 96%,
      10% 96%,
      10% 62%,
      9% 62%,
      9% 84%,
      68% 84%,
      68% 50%,
      52% 50%,
      52% 55%,
      35% 55%,
      35% 87%,
      25% 87%,
      25% 39%,
      15% 39%,
      15% 88%,
      52% 88%
    );
  }

  30% {
    clip-path: polygon(
      0% 53%,
      93% 53%,
      93% 62%,
      68% 62%,
      68% 37%,
      97% 37%,
      97% 89%,
      13% 89%,
      13% 45%,
      51% 45%,
      51% 88%,
      17% 88%,
      17% 54%,
      81% 54%,
      81% 75%,
      79% 75%,
      79% 76%,
      38% 76%,
      38% 28%,
      61% 28%,
      61% 12%,
      55% 12%,
      55% 62%,
      68% 62%,
      68% 51%,
      0% 51%,
      0% 92%,
      63% 92%,
      63% 4%,
      65% 4%
    );
  }

  45% {
    clip-path: polygon(
      0% 33%,
      2% 33%,
      2% 69%,
      58% 69%,
      58% 94%,
      55% 94%,
      55% 25%,
      33% 25%,
      33% 85%,
      16% 85%,
      16% 19%,
      5% 19%,
      5% 20%,
      79% 20%,
      79% 96%,
      93% 96%,
      93% 50%,
      5% 50%,
      5% 74%,
      55% 74%,
      55% 57%,
      96% 57%,
      96% 59%,
      87% 59%,
      87% 65%,
      82% 65%,
      82% 39%,
      63% 39%,
      63% 92%,
      4% 92%,
      4% 36%,
      24% 36%,
      24% 70%,
      1% 70%,
      1% 43%,
      15% 43%,
      15% 28%,
      23% 28%,
      23% 71%,
      90% 71%,
      90% 86%,
      97% 86%,
      97% 1%,
      60% 1%,
      60% 67%,
      71% 67%,
      71% 91%,
      17% 91%,
      17% 14%,
      39% 14%,
      39% 30%,
      58% 30%,
      58% 11%,
      52% 11%,
      52% 83%,
      68% 83%
    );
  }

  76% {
    clip-path: polygon(
      0% 26%,
      15% 26%,
      15% 73%,
      72% 73%,
      72% 70%,
      77% 70%,
      77% 75%,
      8% 75%,
      8% 42%,
      4% 42%,
      4% 61%,
      17% 61%,
      17% 12%,
      26% 12%,
      26% 63%,
      73% 63%,
      73% 43%,
      90% 43%,
      90% 67%,
      50% 67%,
      50% 41%,
      42% 41%,
      42% 46%,
      50% 46%,
      50% 84%,
      96% 84%,
      96% 78%,
      49% 78%,
      49% 25%,
      63% 25%,
      63% 14%
    );
  }

  90% {
    clip-path: polygon(
      0% 41%,
      13% 41%,
      13% 6%,
      87% 6%,
      87% 93%,
      10% 93%,
      10% 13%,
      89% 13%,
      89% 6%,
      3% 6%,
      3% 8%,
      16% 8%,
      16% 79%,
      0% 79%,
      0% 99%,
      92% 99%,
      92% 90%,
      5% 90%,
      5% 60%,
      0% 60%,
      0% 48%,
      89% 48%,
      89% 13%,
      80% 13%,
      80% 43%,
      95% 43%,
      95% 19%,
      80% 19%,
      80% 85%,
      38% 85%,
      38% 62%
    );
  }

  1%,
  7%,
  33%,
  47%,
  78%,
  93% {
    clip-path: none;
  }
}

.movement {
  /* Normally this position would be absolute & on the layers, set to relative here so we can see it on the div */
  position: relative;
  animation: movement 8s step-end infinite;
}

@keyframes movement {
  0% {
    top: 0px;
    left: -20px;
  }

  15% {
    top: 10px;
    left: 10px;
  }

  60% {
    top: 5px;
    left: -10px;
  }

  75% {
    top: -5px;
    left: 20px;
  }

  100% {
    top: 10px;
    left: 5px;
  }
}

.flicker {
  animation: opacity 5s step-end infinite;
}

@keyframes opacity {
  0% {
    opacity: 0.1;
  }

  5% {
    opacity: 0.7;
  }

  30% {
    opacity: 0.4;
  }

  45% {
    opacity: 0.6;
  }

  76% {
    opacity: 0.4;
  }

  90% {
    opacity: 0.8;
  }

  1%,
  7%,
  33%,
  47%,
  78%,
  93% {
    opacity: 0;
  }
}

.font {
  animation: font 7s step-end infinite;
}

@keyframes font {
  0% {
    font-weight: 100;
    color: #e0287d;
    filter: blur(3px);
  }

  20% {
    font-weight: 500;
    color: #fff;
    filter: blur(0);
  }

  50% {
    font-weight: 300;
    color: #1bc7fb;
    filter: blur(2px);
  }

  60% {
    font-weight: 700;
    color: #fff;
    filter: blur(0);
  }

  90% {
    font-weight: 500;
    color: #e0287d;
    filter: blur(6px);
  }
}

.glitch span {
  animation: paths 5s step-end infinite;
}

.glitch::before {
  animation: paths 5s step-end infinite, opacity 5s step-end infinite,
    font 8s step-end infinite, movement 10s step-end infinite;
}

.glitch::after {
  animation: paths 5s step-end infinite, opacity 5s step-end infinite,
    font 7s step-end infinite, movement 8s step-end infinite;
}

.robo {
  color: #da532c;
  font-size: 2em;
  position: fixed;
  right: 2vw;
  bottom: 10vh;
  z-index: 10;
}
